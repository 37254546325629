html {
  text-align: center;
  background: #fefefe;
}
a {
  text-decoration: none;
}
.header-color {
  background: #fefefe;
}
.subheading {
  font-family: "Noto Sans", sans-serif;
  font-size: 40px;
  padding-top: 20px;
  line-height: 40px;
  letter-spacing: normal;
}
/*
.button-bg{
  display: none;
}
*/
.nav {
  z-index: 3;
}

.landing-grid h1 {
  font-family: "Noto Sans", sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: black;
  margin: -10px auto 30px auto;
}
.avatar-img {
  height: 250px;
  border-radius: 100%;
  border: 3px solid black;
  margin: -20px auto 10px auto;
}
/*
.avatar-img:hover{
  height: 260px;
  margin: -25px auto 5px auto;
}
*/
.banner-text {
  background-color: white;
  opacity: 1;
  width: 75%;
  margin: 10px auto 50px auto;
  border-radius: 0px;
  border: 3px solid black;
  box-shadow: -5px 5px black;
  transition: all 0.3s;
}
.name {
  letter-spacing: -0.96px;
}
.banner-text p {
  padding: 3em;
}
.banner-blue {
  background-color: #c0fcdc;
}
.banner-blue:hover {
  background-color: #9aebc0;
  box-shadow: -8px 8px black;
}
.banner-yellow {
  background-color: #fdf591;
}
.banner-yellow:hover {
  background-color: #f0e76f;
  box-shadow: -8px 8px black;
}
.banner-text h3 {
  font-family: "Noto Sans", sans-serif;
  font-size: 40px;
  color: black;
  padding-top: 20px;
}
.banner-text hr {
  border-top: 5px solid black;
  width: 50%;
  margin: auto;
}
.banner-text p {
  font-family: "Noto Sans", sans-serif;
  color: black;
  font-size: 20px;
  padding: 1em;
}
p.contactLink {
  padding: 0px;
}
.social-links {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: auto;
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.social-links i {
  font-size: 5em;
  padding-bottom: 30px;
  margin-bottom: 10px;
  -webkit-transition: margin 0.1s ease-out;
  -moz-transition: margin 0.1s ease-out;
  -o-transition: margin 0.1s ease-out;
}

.social-links i:hover {
  margin-top: -5px;
}

.svg_icon {
  height: 4.5em;
  width: 4.5em;
  padding-bottom: 10px;
  -webkit-transition: margin 0.1s ease-out;
  -moz-transition: margin 0.1s ease-out;
  -o-transition: margin 0.1s ease-out;
}

.svg_icon:hover {
  margin-top: -5px;
}

.about-text {
  text-align: center;
  font-size: 16px;
  margin: 0em 2em 1em 2em;
  line-height: 30px;
}
.tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 3px solid black;
  box-shadow: -5px 5px black;
  width: 300px;
  max-height: 300px;
  background-color: #fdf591;
  margin: 30px;
  padding: 10px;
  transition: all 0.3s;
}
.tileHeader {
  font-size: 24px;
  line-height: 32px;
}
.linkStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.linkStyle a {
  color: #db044d;
}
.linkStyle a:hover {
  text-decoration: underline;
}
.techUsed p {
  margin-bottom: 0px;
}
.tile:hover {
  box-shadow: -8px 8px black;
  background-color: #f0e76f;
}
.copyright {
  border-top: 3px solid black;
  margin-bottom: 0px;
}
#contact-box {
  display: flex;
  flex-direction: column;
}
#contact-box div {
  display: flex;
  justify-content: center;
}
.skipButton {
  margin-right: 1rem;
  position: absolute;
  transform: translateX(-2000%);
  transition: transform 0.3s;
}
.skipButton:focus {
  position: static;
  transform: translateX(0);
}
@media only screen and (max-width: 600px) {
  /*covers navbar for some reason on mobile
  .button-bg{
    display: block;
    position: fixed;
    left: 2px;
    top: 2px;
    height: 50px;
    width: 50px;
    background-color: #fafafa;
    z-index: 2;
    border-radius: 100px;
    border: 1.5px black solid;
  }
  */
  .landing-grid h1 {
    font-size: 2em;
  }
  .nav {
    display: none;
  }
  .page-content {
    margin-top: 3px;
  }
  .banner-text {
    width: 95%;
  }
  .banner-text p {
    font-size: 1em;
    line-height: 1.5em;
    padding: 10px;
  }
  .social-links i {
    padding-left: 50px;
    font-size: 2em;
  }
  .banner-text h3 {
    font-size: 26px;
  }
  .tile p {
    font-size: 1em;
  }
  div.mdl-layout__drawer {
    display: flex;
  }
}

@media only screen and (min-width: 600px) {
  #drawer {
    display: none;
  }
  div.mdl-layout__drawer-button {
    display: none;
  }
  div.mdl-layout__drawer {
    display: none;
  }
}
@media only screen and (min-width: 1600px) {
  .about-text {
    margin: 0em auto 1em auto;
    max-width: 960px;
  }
}
